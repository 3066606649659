import request from '@/utils/request'
//引入 -> 挂载->请求和相应拦截() vue.protopy.axios = axios;
// 获取配置
export const getAllConfig = () => {
    return request({
        url: '/config/getAll',
        method: 'post',
    })
}

// 获取商品类型
export const getProType = () => {
    return request({
        url: '/goods_type/getAll',
        method: 'post',
    })
}

// 获取商品列表
export const getPros = (data) => {
    return request({
        url: '/goods/getListByType',
        method: 'post',
        data
    })
}

// 获取商品详情
export const getProDetail = (data) => {
    return request({
        url: '/goods/getInfoById',
        method: 'post',
        data
    })
}

// 获取推荐商品
export const getProsHot = (data) => {
    return request({
        url: '/goods/getHot',
        method: 'post',
        data
    })
}

// 获取文章类型
export const getNewType = (data) => {
    return request({
        url: '/news_type/getAll',
        method: 'post',
        data
    })
}

// 获取文章列表
export const getNews = (data) => {
    return request({
        url: '/news/getListByType',
        method: 'post',
        data
    })
}

// 获取文章详情
export const getNewsDetail = (data) => {
    return request({
        url: '/news/getInfoById',
        method: 'post',
        data
    })
}

// 添加留言
export const addContact = (data) => {
    return request({
        url: '/contact/add',
        method: 'post',
        data
    })
}

// 获取图片
export const getImg = (data) => {
    return request({
        url: '/images/getListByType',
        method: 'post',
        data
    })
}

//获取视频列表
export const getVideo = (data) => {
    return request({
        url: '/video/getVideo',
        method: 'post',
        data
    })
}

//获取发展历程列表
export const getHistory = () => {
    return request({
        url: '/history/getHistory',
        method: 'post'
    })
}