<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img
          class="add"
          src="@/assets/address.png"
        >
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link :to="{name: 'Media'}">媒体聚焦</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link to="/media/spzx">文化</router-link>
      </div>
      <div class="wccp-wrap">
        <ul class="media-list">
          <li
            v-for="item in news"
            :key="item.id + 'news'"
          >
            <router-link :to="{name: 'SpzxDetail', params:{video:item.videofile,title:item.title,id:item.id}}">
              <div>
                <img :src="item.image">
                <div>
                  <span></span>
                </div>
              </div>
              <p class="ellipsis">{{item.title}}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideo } from '@/api'
export default {
  name: 'MediaSpzx',
  data() {
    return {
      news: [],
    }
  },
  created() {
    getVideo({ type: 2 })
      .then((res) => {
        if (res.data.code) {
          this.news = res.data.data.videoArr
          console.log(this.news);
        }
      })
      .catch((err) => {
        console.error(err)
      })
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .wrap {
    // min-width: 1760px;
    background: #f2f2f2;
    padding: 0 20px;
    > div {
      // width: 1760px;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > div {
      > .wccp-wrap {
        background: #fff;
        padding: 15px;
      }
    }
  }
}
</style>